import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Handling Languages`}</h1>
    <p>{`There are several PR's addressing Locales and the use of language selection in the pull requests for the community repo. `}<a parentName="p" {...{
        "href": "https://github.com/makerdao/community-portal/pulls?q=is%3Apr+is%3Aclosed+author%3Amaximumcrash+language"
      }}>{`Feel free to peruse`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      